import React from "react";
import styles from "./NotebookViewer.module.css";

const NotebookViewer = () => {
  return (
    <div className={styles.heading}>
      <div>Opening notebook server...</div>
      <div>
        If it is not redirected, you can click{" "}
        <a
          href={process.env.REACT_APP_NOTEBOOK_URL}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </div>
    </div>
  );
};
// const NotebookViewer = () => {
//   return (
//     <iframe
//       src="http://34.133.111.5:8888"
//       noborder="0"
//       width="830"
//       height="800"
//       title=""
//       // scrolling="yes"
//       seamless
//     ></iframe>
//   );
// };

export default NotebookViewer;
