import React, { useState } from "react";
import { useDataContext } from "../../DataContext";
import styles from "./Upload.module.css";

const Upload = ({ tab, index }) => {
  const { projects, setProjects } = useDataContext();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      var requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_upload_url}`, requestOptions)
        .then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          console.log(result.Response); // Log the URL directly
          if (tab === "projectInfo") {
            const updatedProjects = [...projects];
            updatedProjects[index].links.projectInfo = result.Response;
            setProjects(updatedProjects);
          } else if (tab === "experiment") {
            const updatedProjects = [...projects];
            updatedProjects[index].links.experiment = result.Response;
            setProjects(updatedProjects);
          } else if (tab === "data") {
            const updatedProjects = [...projects];
            updatedProjects[index].links.data = result.Response;
            setProjects(updatedProjects);
          } else if (tab === "metrics") {
            const updatedProjects = [...projects];
            updatedProjects[index].links.metrics = result.Response;
            setProjects(updatedProjects);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <div id="content-wrapper">
      <div className={styles.gridLayout}>
        <div className={styles.container}>
          <input
            type="file"
            className={styles.fileInput}
            onChange={handleFileChange}
          />
          <button className={styles.uploadButton} onClick={handleUpload}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default Upload;
