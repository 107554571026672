import React from "react";
import styles from "./Knobs.module.css";
import InputControl from "../InputControl/InputControl.js";
import { useDataContext } from "../../DataContext";

const Knobs = ({ index }) => {
  const { projects, setProjects } = useDataContext();

  const updateKnob1 = (knob1) => {
    const updatedProjects = [...projects];
    updatedProjects[index].knobs.knob1 = knob1;
    setProjects(updatedProjects);
  };
  const updateKnob2 = (knob2) => {
    const updatedProjects = [...projects];
    updatedProjects[index].knobs.knob2 = knob2;
    setProjects(updatedProjects);
  };
  const updateKnob3 = (knob3) => {
    const updatedProjects = [...projects];
    updatedProjects[index].knobs.knob3 = knob3;
    setProjects(updatedProjects);
  };
  const updateKnob4 = (knob4) => {
    const updatedProjects = [...projects];
    updatedProjects[index].knobs.knob4 = knob4;
    setProjects(updatedProjects);
  };

  return (
    <div className={styles.detail}>
      <InputControl
        label="Knob 1"
        type="text"
        placeholder="Enter the Knob 1"
        value={projects[index].knobs.knob1}
        onChange={(e) => updateKnob1(e.target.value)}
      />
      <InputControl
        label="Knob 2"
        type="text"
        placeholder="Enter the Knob 2"
        value={projects[index].knobs.knob2}
        onChange={(e) => updateKnob2(e.target.value)}
      />
      <InputControl
        label="Knob 3"
        type="text"
        placeholder="Enter the Knob 3"
        value={projects[index].knobs.knob3}
        onChange={(e) => updateKnob3(e.target.value)}
      />
      <InputControl
        label="Knob 4"
        type="text"
        placeholder="Enter the Knob 4"
        value={projects[index].knobs.knob4}
        onChange={(e) => updateKnob4(e.target.value)}
      />
    </div>
  );
};

export default Knobs;
