// DataContext.js
import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const projectInfo = {
    name: "",
    description: "",
    team: [],
    primaryOwner: "",
    secondaryOwner: "",
    experimentSetPlanned: "",
    featureSetPlanned: "",
  };
  const experiments = [
    {
      researchQuestion: "",
      whyExperiment: "",
      nullHypothesis: "",
      field1: "",
      field2: "",
      field3: "",
    },
  ];
  const data = {
    description: "",
    source: "",
    summary: "",
    trainSet: "",
    validationSet: "",
    testSet: "",
    randomizationMethod: "",
  };
  const metrics = {
    primary: "",
    secondary: "",
  };
  const knobs = {
    knob1: "",
    knob2: "",
    knob3: "",
    knob4: "",
  };
  const result = {
    testName: "",
    timeStamp: "",
    sampleSize: "",
    status: "",
    pValue: "",
    alpha: "",
    testStatistics: "",
    confidenceIntervals: "",
  };
  const conclusion = {
    summary: "",
    description: "",
  };
  const impareas = [
    {
      item: "",
      desc: "",
    },
  ];

  const links = {
    projectInfo: "",
    experiment: "",
    data: "",
    metrics: "",
  };

  const tempProject = {
    projectInfo,
    experiments,
    data,
    metrics,
    knobs,
    result,
    conclusion,
    impareas,
    links,
  };
  const [projects, setProjects] = useState([tempProject]);
  const [projectCnt, setProjectCnt] = useState(1);
  const [selected, setSelected] = useState(0);

  const values = {
    projectInfo,
    experiments,
    data,
    metrics,
    knobs,
    result,
    conclusion,
    impareas,
    links,
    projects,
    setProjects,
    projectCnt,
    setProjectCnt,
    tempProject,
    selected,
    setSelected,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

export const useDataContext = () => useContext(DataContext);
