import { useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar.js";
import styles from "./ABTestAssumption.module.css";
import { Helmet } from "react-helmet";

function BaselineConversionRate() {
  const apiKey = process.env.REACT_APP_VITE_API_KEY;
  const [formData, setFormData] = useState({
    column1: "",
    column2: "",
    column1_value: "",
    bool: "true",
    threshold: 5,
    file: null,
  });
  const [outputData, setOutputData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [col_values, setColumnValues] = useState([]);
  const handleChange = async (evt) => {
    const changedField = evt.target.name;
    let newValue;
    if (changedField === "file") {
      newValue = evt.target.files[0];
      try {
        const formData2 = new FormData();
        formData2.append("file", newValue);
        await axios({
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            "x-functions-key": `${apiKey}`,
          },
          url: `https://functions-abexp.azurewebsites.net/get_columns`,
          data: formData2,
        }).then((res) => {
          setColumns(res.data.columns);
        });
      } catch (e) {
        console.log(e.message);
      }
    } else {
      newValue = evt.target.value;
      if (changedField === "column1") {
        try {
          const formData3 = new FormData();
          formData3.append("file", formData.file);
          formData3.append("column1", newValue);
          await axios({
            method: "post",
            headers: {
              "Content-Type": "multipart/form-data",
              "x-functions-key": `${apiKey}`,
            },
            url: `https://functions-abexp.azurewebsites.net/get_column_values`,
            data: formData3,
          }).then((res) => {
            setColumnValues(res.data.column_val);
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    }
    setFormData((currData) => {
      currData[changedField] = newValue;
      return { ...currData };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-functions-key": `${apiKey}`,
        },
        url: `https://functions-abexp.azurewebsites.net/baseline_conversion_rate`,
        data: formData,
      }).then((res) => {
        if (formData.bool === "true") {
          setOutputData(
            res.data[
              `Baseline conversion rate(p1) of ${formData.column1} ${formData.column1_value}`
            ]
          );
        } else {
          setOutputData(
            res.data[
              `Baseline conversion rate(p1) of ${formData.column1} ${formData.column1_value} for greater than or equal to threshold value ${formData.threshold}`
            ]
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const pageDescription =
    "Baseline conversion rate refers to the observed or expected conversion rate in the control group before any changes.";
  const pageKeywords = "Conversion rate, threshold, boolean variable";

  return (
    <>
      <Helmet>
        <title>Baseline Conversion Rate</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
      <Navbar />
      <div className={styles.app_container}>
        <div className={styles.head}>
          <h1 className={styles.midheading}>
            Baseline Conversion Rate Calculator
          </h1>
          <p className={styles.pageDescription}>{pageDescription}</p>
        </div>

        <div className={styles.input_container}>
          <h2 className={styles.subHeading}>Input</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Data</label>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Column 1</label>
                <select
                  className={styles.design}
                  name="column1"
                  value={formData.column1}
                  onChange={handleChange}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Column1 Value</label>
                <select
                  name="column1_value"
                  value={formData.column1_value}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Value--</option>
                  {col_values.map((col_value, index) => (
                    <option key={index} value={col_value}>
                      {col_value}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Column 2</label>
                <select
                  name="column2"
                  value={formData.column2}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Bool</label>
                <select
                  name="bool"
                  id=""
                  onChange={handleChange}
                  value={formData.bool}
                  className={styles.design}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Threshold</label>
                <input
                  type="number"
                  value={formData.threshold}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>
            <div className={styles.formElement}>
              <button className={styles.button}>Submit</button>
            </div>
          </form>
        </div>

        <div className={styles.input_container}>
          <h2 className={styles.subHeading}>Result</h2>
          <div className={styles.row}>
            <input type="text" name="query" value={outputData} readOnly></input>
          </div>
        </div>
      </div>
    </>
  );
}

export default BaselineConversionRate;
