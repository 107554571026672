import React from "react";
import Body from "./components/Body/Body";
import "./App.css";
import { DataProvider } from "./DataContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/auth/signUp";
import Login from "./pages/auth/login";
import ResetPasswordPage from "./pages/auth/forgotPassword";
import Tools from "./pages/Tools/Tools.js";
import { useEffect, useState } from "react";
import { auth } from "./firebase.js";
import Home from "./components/Home";
import ABTestAssumption from "./pages/Tools/ABTestAssumption.js";
import BaselineConversionRate from "./pages/Tools/BaselineConversionRate.js";
import SampleSizeCalculator from "./pages/Tools/SampleSizeCalculator.js";
import ChiSquaredTest from "./pages/Tools/ChiSquaredTest.js";
import TwoSampleTtest from "./pages/Tools/TwoSampleTtest.js";
import PairedTTest from "./pages/Tools/PairedTTest.js";
import MannWhitneyUTest from "./pages/Tools/MannWhitneyUTest.js";
import WilcoxonSignedRankTest from "./pages/Tools/WilcoxonSignedRankTest.js";

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return async () => {
      unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/my-account"
          element={
            user ? (
              <DataProvider>
                <Body />
              </DataProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route path="/forgot-password" element={<ResetPasswordPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/tools" element={<Tools />} />
        <Route
          exact
          path="/tools/ab-test-assumption"
          element={<ABTestAssumption/>}
        ></Route>
        <Route
          exact
          path="/tools/baseline-conversion-rate"
          element={<BaselineConversionRate />}
        ></Route>
        <Route
          exact
          path="/tools/sample-size-calculator"
          element={<SampleSizeCalculator/>}
        ></Route>
        <Route
          exact
          path="/tools/chisquared-test"
          element={<ChiSquaredTest/>}
        ></Route>
        <Route
          exact
          path="/tools/two-sample-t-test"
          element={<TwoSampleTtest/>}
        ></Route>
        <Route
          exact
          path="/tools/paired-t-test"
          element={<PairedTTest/>}
        ></Route>
        <Route
          exact
          path="/tools/mann-whitney-U-test"
          element={<MannWhitneyUTest/>}
        ></Route>
        <Route
          exact
          path="/tools/wilcoxon-signed-rank-test"
          element={<WilcoxonSignedRankTest/>}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
