import React, { useEffect, useState } from "react";
import styles from "../Body/Body.module.css";
import { ArrowDown } from "react-feather";
import Editor from "../Editor/Editor";
import Navbar from "../Navbar/Navbar.js";
import generatePDF from "../../PDFGenerator";
import generateJSON from "../../GenerateJSON";
import { useDataContext } from "../../DataContext";
import Banner from "../Banner/Banner.js";
import axios from "axios";
import { auth } from "../../firebase.js";
import Sidebar from "../Sidebar/Sidebar";

const Body = () => {
  const sections = [
    "Project Info",
    "Experiment",
    "Data",
    "Metrics",
    "Notebook",
    "Knobs",
    "Result",
    "Conclusion",
    "Improvement Areas",
  ];
  const { setProjectCnt, projects, setProjects, selected } = useDataContext();
  const [showBanner, setShowBanner] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    if (showBanner === true) {
      setTimeout(() => {
        setShowBanner(false);
      }, 3000);
    }
  }, [showBanner, selected]);

  const pdfData = {
    projectInfo: projects[selected].projectInfo,
    experiment: projects[selected].experiments,
    data: projects[selected].data,
    metrics: projects[selected].metrics,
    knobs: projects[selected].knobs,
    result: projects[selected].result,
    conclusion: projects[selected].conclusion,
    impareas: projects[selected].improvementAreas,
  };
  // const htmlFileURL =
  //   "https://storage.googleapis.com/abexperiments/paragraph.html";

  const handleSaveClick = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const token = await user.getIdToken(); // Get the Firebase token
        const userId = user.email; // Get the user's email

        const response = await axios.get(
          `${process.env.REACT_APP_backend_url}/api/get-user-auth-status/${userId}`,
          // `http://localhost:5000/api/get-user-auth-status/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send the user's Firebase token
            },
          }
        );

        const isAuthenticated = response.data.isAuthenticated;

        if (isAuthenticated) {
          const combinedData = {
            user: userId, // Use the user's Firebase UID
            projects: projects,
            userType: "Primary",
          };
          const saveResponse = await axios.post(
            `${process.env.REACT_APP_backend_url}/api/save-all-data`,
            // `http://localhost:5000/api/save-all-data`,
            combinedData,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Send the user's Firebase token
              },
            }
          );

          for (let i = 0; i < projects.length; i++) {
            const teamEmails = projects[i].projectInfo.team;
            for (const teamMemberEmail of teamEmails) {
              await axios.post(
                `${process.env.REACT_APP_backend_url}/api/save-all-data`,
                // `http://localhost:5000/api/save-all-data`,
                {
                  projects: [projects[i]],
                  user: teamMemberEmail,
                  userType: "Secondary",
                  isAuthenticated: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`, // Send the user's Firebase token
                  },
                }
              );
            }
          }

          console.log("All data saved:", saveResponse.data);
          setShowBanner(true);
          setMsg("Data saved successfully!");
        } else {
          console.log("User is not registered with us.");
          setShowBanner(true);
          setMsg("User is not registered with us. Please contact our team!");
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const fetchDataFromMongoDB = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_backend_url}/api/${userId}`
        // `http://localhost:5000/api/${userId}`
      );

      const firstData = response.data[0];

      if (firstData && firstData.projects) {
        if (firstData.projects.length > 0) {
          setProjects(firstData.projects);
          setProjectCnt(firstData.projects.length);
        } else console.log(firstData);
      } else {
        setProjects(projects);
        setProjectCnt(1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      fetchDataFromMongoDB(user.email);
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className={styles.content}>
        <Sidebar />
        <div className={styles.container}>
          <div className={styles.container}>
            <div className={styles.toolbar}>
              <button onClick={() => generatePDF(pdfData)}>
                Download PDF <ArrowDown />
              </button>
              <button onClick={() => generateJSON(pdfData)}>
                Download Json <ArrowDown />
              </button>
            </div>
            <div className={styles.main}>
              <Editor
                sections={sections}
                setShowBanner={setShowBanner}
                setMsg={setMsg}
                index={selected}
              />
            </div>
          </div>
          <button className={styles.btn} onClick={handleSaveClick}>
            Save Project
          </button>
        </div>
      </div>
      {showBanner && <Banner title={msg} />}
    </>
  );
};

export default Body;
