import { useState } from "react";
import Axios from "axios";
import Navbar from "../../components/Navbar/Navbar.js";
import styles from "./ABTestAssumption.module.css";
import { Helmet } from "react-helmet";

function SampleSizeCalculator() {
  const apiKey = process.env.REACT_APP_VITE_API_KEY;
  const [formData, setFormData] = useState({
    p1: 0,
    mde: 0,
    alpha: 0.05,
    data_type: "Absolute",
    power: 0.8,
    n_side: 2,
  });
  const [outputData, setOutputData] = useState([]);
  const handleChange = (evt) => {
    setFormData((currData) => {
      return {
        ...currData,
        [evt.target.name]: evt.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-functions-key": `${apiKey}`,
        },
        url: `https://functions-abexp.azurewebsites.net/sample_size`,
        data: formData,
      }).then((res) => {
        setOutputData(res.data["Sample size"]);
      });
    } catch (e) {
      console.log(e.message);
    }
  };
  const pageDescription =
    "Sample Size determines the amount of data required to achieve a statistically significant result.";
  const pageKeywords =
    "Baseline conversion rate, Minimum detectable Effect(mde), alpha, power, Experimental conversion rate, effect size";

  return (
    <>
      <Helmet>
        <title>Sample Size Calculator</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
      <Navbar />

      <div className={styles.app_container}>
        <div className={styles.head}>
          <h1 className={styles.midheading}>Sample Size Calculator</h1>
          <p className={styles.pageDescription}>{pageDescription}</p>
        </div>

        <div className={styles.input_container}>
          <h2 className={styles.subHeading}>Input</h2>

          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Baseline Conversion Rate</label>
                <input
                  type="number"
                  name="p1"
                  value={formData.p1}
                  onChange={handleChange}
                  className={styles.design}
                ></input>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>
                  Minimum Detectable Effect
                </label>
                <input
                  type="number"
                  name="mde"
                  value={formData.mde}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Significance(α)</label>
                <input
                  type="number"
                  name="alpha"
                  value={formData.alpha}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Data Type</label>
                <div>
                  <select
                    name="data_type"
                    value={formData.data_type}
                    onChange={handleChange}
                    className={styles.design}
                  >
                    <option value="Absolute">Absolute</option>
                    <option value="Relative">Relative</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Power</label>
                <input
                  type="number"
                  name="power"
                  value={formData.power}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>N Side</label>
                <input
                  type="number"
                  name="n_side"
                  value={formData.n_side}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>
            <div className={styles.formElement}>
              <button className={styles.button}>Submit</button>
            </div>
          </form>
        </div>

        <div className={styles.input_container}>
          <h3 className={styles.subHeading}>Result</h3>
          <div className={styles.formElement}>
            <input type="text" name="query" value={outputData} readOnly></input>
          </div>
        </div>
      </div>
    </>
  );
}

export default SampleSizeCalculator;
