import React, { useState } from "react";
import styles from "./ImprovementAreas.module.css";
import InputControl from "../InputControl/InputControl";
import { useDataContext } from "../../DataContext";

const ImprovementAreas = ({ index }) => {
  const { projects, setProjects } = useDataContext();

  const [current, setCurrent] = useState({
    item: "",
    desc: "",
  });

  const handleAdd = () => {
    if (current.item.trim() !== "" && current.desc.trim() !== "") {
      let newProjects = [...projects];
      if (newProjects[index].improvementAreas) {
        newProjects[index].improvementAreas.push(current);
      } else {
        newProjects[index].improvementAreas = [current];
      }
      setProjects(newProjects);
      setCurrent({
        item: "",
        desc: "",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAdd();
    }
  };

  const MemoizedItem = React.memo(({ field }) => (
    <div className={styles.items}>
      <li className={styles.list}>{field.item}</li>
      <li className={styles.list}>{field.desc}</li>
    </div>
  ));

  return (
    <div>
      <div className={styles.column}>
        <div className={styles.detail}>
          <InputControl
            label="Add Item"
            placeholder="Add Item"
            value={current.item}
            type="text"
            onChange={(e) => {
              setCurrent({ ...current, item: e.target.value });
            }}
          />
          <InputControl
            label="Add Description"
            placeholder="Add Description of the item"
            value={current.desc}
            type="text"
            onChange={(e) => {
              setCurrent({ ...current, desc: e.target.value });
            }}
            onKeyDown={handleKeyDown}
          />
          <button className={styles.achButton} onClick={handleAdd}>
            Add
          </button>
        </div>

        {projects[index].improvementAreas?.map((field, idx) => (
          <MemoizedItem key={idx} field={field} />
        ))}
      </div>
    </div>
  );
};

export default ImprovementAreas;
