import React, { useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar.js";
import styles from "./ABTestAssumption.module.css";
import { Helmet } from "react-helmet";

function ABTestAssumption() {
  const apiKey = process.env.REACT_APP_VITE_API_KEY;
  const [formData, setFormData] = useState({
    file: null,
    sample_size: 0,
    column1: "",
    column1_value1: "",
    column1_value2: "",
    column2: "",
    alpha: 0.05,
    paired_data: "False",
  });
  const [outputData, setOutputData] = useState([{ Result: [] }, { Note: "" }]);
  const [columns, setColumns] = useState([]);
  const [col_values, setColumnValues] = useState([]);

  const handleChange = async (evt) => {
    const changedField = evt.target.name;
    let newValue;
    if (changedField === "file") {
      newValue = evt.target.files[0];
      try {
        const formData2 = new FormData();
        formData2.append("file", newValue);
        await axios({
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            "x-functions-key": `${apiKey}`,
          },
          url: "https://functions-abexp.azurewebsites.net/get_columns",
          data: formData2,
        }).then((res) => {
          setColumns(res.data.columns);
        });
      } catch (e) {
        console.log(e.message);
      }
    } else {
      newValue = evt.target.value;
      if (changedField === "column1") {
        try {
          const formData3 = new FormData();
          formData3.append("file", formData.file);
          formData3.append("column1", newValue);
          await axios({
            method: "post",
            headers: {
              "Content-Type": "multipart/form-data",
              "x-functions-key": `${apiKey}`,
            },
            url: "https://functions-abexp.azurewebsites.net/get_column_values",
            data: formData3,
          }).then((res) => {
            setColumnValues(res.data.column_val);
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    }

    setFormData((currData) => {
      currData[changedField] = newValue;
      return { ...currData };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-functions-key": `${apiKey}`,
        },
        url: "https://functions-abexp.azurewebsites.net/AB_Test_assumption",
        data: formData,
      }).then((res) => {
        setOutputData(res.data);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const pageDescription =
    "We check the assumptions of normality and homogeneity of variances to find which test is suitable for the given dataset.";
  const pageKeywords = "Sample size, Categorical variables, alpha, paired_data";

  return (
    <>
      <Helmet>
        <title>AB Test Assumption</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
      <Navbar />
      <div className={styles.app_container}>
        <div className={styles.head}>
          <h1 className={styles.midheading}>AB Test Assumption</h1>
          <p className={styles.pageDescription}>{pageDescription}</p>
        </div>

        <div className={styles.input_container}>
          <h2 className={styles.subHeading}>Input</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label>Data</label>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label>Sample Size</label>
                <input
                  type="number"
                  name="sample_size"
                  value={formData.sample_size}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label>Column-1</label>
                <select
                  name="column1"
                  value={formData.column1}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label>Column-1 Value-1</label>
                <select
                  name="column1_value1"
                  value={formData.column1_value1}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Value--</option>
                  {col_values.map((col_value, index) => (
                    <option key={index} value={col_value}>
                      {col_value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label>Column-1 Value-2</label>
                <select
                  name="column1_value2"
                  value={formData.column1_value2}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Value--</option>
                  {col_values.map((col_value, index) => (
                    <option key={index} value={col_value}>
                      {col_value}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label>Column-2</label>
                <select
                  name="column2"
                  value={formData.column2}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label>Alpha</label>
                <input
                  type="number"
                  name="alpha"
                  value={formData.alpha}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label>Paired Data</label>
                <select
                  name="paired_data"
                  value={formData.paired_data}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
              </div>
            </div>
            <div className={styles.formElement}>
              <button className={styles.button}>Submit</button>
            </div>
          </form>
        </div>

        <div className={styles.input_container}>
          <h2 className={styles.subHeading}>Result</h2>
          <div className={styles.row}>
            <div className={styles.formElement}>
              <label>Result</label>
              <textarea
                className={styles.note}
                type="text"
                value={outputData[0].Result.join("\n")}
                readOnly
              />
            </div>
            <div className={styles.formElement}>
              <label>Note</label>
              <textarea
                className={styles.note}
                type="text"
                value={outputData[1].Note}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ABTestAssumption;
