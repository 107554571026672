import React from "react";
import Header from "../../components/Navbar/Navbar.js";
import Footer from "../../components/footer/index";
import Subscribe from "../../components/subscribe/index";

const Home = () => {
  function handleButtonClick(event) {
    event.preventDefault();

    const pricingTableElement = document.getElementById("pricingTable");
    pricingTableElement.scrollIntoView({ behavior: "smooth" });
  }
  // const htmlFileURL =
  //   "https://storage.googleapis.com/abexperiments/paragraph.html";
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header mb-5">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">
                AB Experiment
              </div>
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                ABExperiment for Websites and Chatbots
              </h1>
              <p className="text-white mb-4 animated slideInRight">
                Experimentation platform for Data Products.
              </p>
              <a
                href="/set-up"
                className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight"
              >
                Set up
              </a>
              <a
                href="#pricingTable"
                className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                onClick={handleButtonClick}
              >
                Buy
              </a>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid img-thumbnail mb-5"
                src="https://testing.abexperiment.com/site2/images/banner.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(20, 24, 62, 0.7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn btn-square bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-light p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-light px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid img-thumbnail"
                  src="https://testing.abexperiment.com/site2/images/pic01.jpg"
                  alt=""
                  style={{ maxWidth: "50vw", height: "40vh" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Set up steps
              </div>
              {/* <h1 className="mb-4">First time set up</h1> */}
              {/* <p className="mb-4">
                Here are one time set up steps :
                <li>Point or connect your domain to hosting and </li>
                <li>
                  point Kreate tool to automatic blog creation from your content
                </li>
                <br />
                <br />
                KREATE runs your website on cloud - Azure/GCP/AWS. We recommend
                that you give KREATE-SUPPORT access to domain or let KREATE HOST
                DNS in Azure/GCP/AWS. If this is not possible, connect your
                domain to KREATE via the pointing method (cname in DNS).
                <br />
                Once set up you can focus on content writing. Your web desig,
                web pages and blog publishing will be at auto pilot.
              </p> */}
              {/* <iframe
                src={htmlFileURL}
                title="Embedded HTML"
                width="100%"
                height="600px"
              /> */}
              {/* <p className="mb-4">
                Once domain is ready, you are ready to publish
              </p> */}
              {/* <div className="row g-3">
                <div className="col-sm-6">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Intelligent
                    websites
                  </h6>
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Well-tested
                  </h6>
                </div>
                <div className="col-sm-6">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Portable to
                    any hosting
                  </h6>
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Low Prices
                  </h6>
                </div>
              </div> */}
              <div className="col-sm-6">
                ABExperiment lets you experiment with websites and chatbots. Our
                tool makes it easy to test different versions of your landing
                pages, marketing campaigns, and website to see what works best.{" "}
              </div>
              <div className="d-flex align-items-center mt-4">
                <a
                  className="btn btn-primary rounded-pill px-4 me-3"
                  href="/set-up"
                >
                  Read More
                </a>
                <a
                  className="btn btn-outline-primary btn-square me-3"
                  href="https://facebook.com/kreatewebsites"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-primary btn-square me-3"
                  href="https://instagram.com/kreatewebsites?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="btn btn-outline-primary btn-square"
                  href="https://www.linkedin.com/company/kreatewebsites/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Our Differentiation
              </div>
              <h1 className="mb-4">
                Experiment with Content, Website and ChatBots
              </h1>
              <p className="mb-4">
                ABExperiment lets you do ab testing with websites as well as
                with chatbots. Our tool is designed to be open and
                collaborative. You can import existing content, methods into it.
                You can export your experiment, insights. You can share them
                with others. ABExperiment toolset provides guidance at every
                stage but it let you customize experiment code. It is designed
                for democratizing experiments.
              </p>
              <a className="btn btn-primary rounded-pill px-4" href="">
                Read More
              </a>
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-robot fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Robotic Automation</h5>
                        <p>
                          Use automation and generative AI to generate web
                          templates with high accuracy. With automation you can
                          generate multiple variations of landing pages
                        </p>
                        <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-power-off fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Machine learning</h5>
                        <p>
                          Use advance machine learning to classify content,
                          create keywords, create meta data, create description
                          etc. Save time on catching up with SEO. Focus on
                          content and users
                        </p>
                        <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-graduation-cap fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Win with search engine</h5>
                        <p>
                          As websites are built from content, these perform well
                          for organic search. If Search engine change algorithm,
                          KREATE can regenerate according to new algorithm{" "}
                        </p>
                        <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-brain fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Predictive Analysis</h5>
                        <p>
                          To publish at scale - KREATE can recommend or
                          automatically suggest which pages or images should be
                          shown together. It can match text with images/videos
                          or vice versa
                        </p>
                        <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-primary feature pt-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn"
              data-wow-delay="0.3s"
            >
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                Why Choose Us
              </div>
              <h1 className="text-white mb-4">
                Experimentation platform for Data Product
              </h1>
              <p className="text-light mb-4">
                Data products require validation both of whether the algorithm
                works, and of whether users like it. ABExperiment lets you
                innovate and build data product. From your data you can build
                website, mobile apps and chatbots.
              </p>
              {/* <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Build website</span>
              </div> */}
              {/* <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Build app</span>
              </div> */}
              {/* <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Build Chatbot</span>
              </div> */}
              <div className="row g-4 pt-3">
                <div className="col-sm-6">
                  <div
                    className="d-flex rounded p-3"
                    style={{ background: "rgba(255, 255, 255, 0.1)" }}
                  >
                    <i className="fa fa-users fa-3x text-white"></i>
                    <div className="ms-3">
                      <h2 className="text-white mb-0" data-toggle="counter-up">
                        50000
                      </h2>
                      <p className="text-white mb-0">User visit</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="d-flex rounded p-3"
                    style={{ background: "rgba(255, 255, 255, 0.1)" }}
                  >
                    <i className="fa fa-check fa-3x text-white"></i>
                    <div className="ms-3">
                      <h2 className="text-white mb-0" data-toggle="counter-up">
                        10000
                      </h2>
                      <p className="text-white mb-0">Pages Built</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 align-self-end text-center text-md-end wow fadeIn"
              data-wow-delay="0.5s"
            >
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/feature.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Case Study
            </div>
            <h1 className="mb-4">Explore Our Recent AI Case Studies</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/project-1.jpg"
                  alt=""
                />
                <a className="case-overlay text-decoration-none" href="">
                  <small>USA City Trip</small>
                  <h5 className="lh-base text-white mb-3">
                    Website for travel
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/project-2.jpg"
                  alt=""
                />
                <a className="case-overlay text-decoration-none" href="">
                  <small>AB Experiment</small>
                  <h5 className="lh-base text-white mb-3">
                    Online blog and book for A/B testing
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/project-3.jpg"
                  alt=""
                />
                <a className="case-overlay text-decoration-none" href="">
                  <small>Sports Website</small>
                  <h5 className="lh-base text-white mb-3">
                    A sports website to show daily score
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Popular FAQs
            </div>
            <h1 className="mb-4">Frequently Asked Questions</h1>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="accordion" id="accordionFAQ1">
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.1s"
                >
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How to build a website?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionFAQ1"
                  >
                    <div className="accordion-body">
                      Use www.kreatewebsites.com and buy package. Set up your
                      domain and start publishing.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.2s"
                >
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How long will it take to get a new website?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionFAQ1"
                  >
                    <div className="accordion-body">
                      KREATE will immediately create website in few seconds and
                      get hosting ready. If we have access to dyour domain, it
                      will be set up too or you will have to point to us. After
                      first time set up web pages will be generated and
                      deployment will happen in less than {"<"} 5 min.{" "}
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.3s"
                >
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Do you only create HTML websites?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionFAQ1"
                  >
                    <div className="accordion-body">
                      We can create HTML, React/Node js, ASPX , PHP website. For
                      blog publishing HTML is portable and efficient.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      Will my website be mobile-friendly?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionFAQ1"
                  >
                    <div className="accordion-body">
                      Yes. Absolutely. One of advantage of generation is as new
                      form factor and devices come, we can adapt KREATE engine.
                      Accordingly your website pages will get generated without
                      any development effort.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="accordion" id="accordionFAQ2">
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.5s"
                >
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Will you maintain my site for me?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionFAQ2"
                  >
                    <div className="accordion-body">
                      Yes we will support. If there is issue or downtime we will
                      support. If you need custom fucntionality or development
                      we can support that too.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      I’m on a strict budget. Do you have any low cost options?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionFAQ2"
                  >
                    <div className="accordion-body">
                      yes, our solution is design for low cost.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.7s"
                >
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Will you maintain my site for me?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionFAQ2"
                  >
                    <div className="accordion-body">
                      Yes we will maintain site and ensure it is available 99%+
                      time.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeIn"
                  data-wow-delay="0.8s"
                >
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Can you buy domain for my website
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionFAQ2"
                  >
                    <div className="accordion-body">
                      Yes we can buy domain and point it your website. If you
                      sign up for one year
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Testimonial
              </div>
              <h1 className="mb-4">See Sites running on KREATE!</h1>
              <p className="mb-4">
                Travel, Sports, Real-Estate, Technology blogs and more
              </p>
              <a
                className="btn btn-primary rounded-pill px-4"
                href="https://blog.kreatewebsites.com/"
              >
                Read More
              </a>
            </div>
            <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
              <div className="owl-carousel testimonial-carousel border-start border-primary">
                <div className="testimonial-item ps-5">
                  <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                  <p className="fs-4">
                    USA City Trip and Europe City Trip is site that is helping
                    people create vacation memories{" "}
                  </p>
                </div>
                <div className="testimonial-item ps-5">
                  <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                  <p className="fs-4">
                    Day night cricket show cricket summary to sports fan on web
                    and on phone app
                  </p>
                </div>
                <div className="testimonial-item ps-5">
                  <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                  <p className="fs-4">
                    Apartment Sale enable people to browse rental and sale
                    properties
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Home;
