import React from "react";
import styles from "./Result.module.css";
import InputControl from "../InputControl/InputControl.js";
import { useDataContext } from "../../DataContext";

const Result = ({ index }) => {
  const { projects, setProjects } = useDataContext();

  const updateTestName = (testName) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.testName = testName;
    setProjects(updatedProjects);
  };
  const updateTimeStamp = (timeStamp) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.timeStamp = timeStamp;
    setProjects(updatedProjects);
  };
  const updateSampleSize = (sampleSize) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.sampleSize = sampleSize;
    setProjects(updatedProjects);
  };
  const updateStatus = (status) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.status = status;
    setProjects(updatedProjects);
  };
  const updatepValue = (pValue) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.pValue = pValue;
    setProjects(updatedProjects);
  };
  const updatepAlpha = (alpha) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.alpha = alpha;
    setProjects(updatedProjects);
  };
  const updatetestStatistics = (testStatistics) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.testStatistics = testStatistics;
    setProjects(updatedProjects);
  };
  const updateconfidenceIntervals = (confidenceIntervals) => {
    const updatedProjects = [...projects];
    updatedProjects[index].result.confidenceIntervals = confidenceIntervals;
    setProjects(updatedProjects);
  };
  return (
    <div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Test Name"
            type="text"
            placeholder="Enter the test name"
            value={projects[index].result.testName}
            onChange={(e) => updateTestName(e.target.value)}
          />
          <InputControl
            label="Time Stamp"
            type="datetime-local"
            placeholder="Enter the time"
            value={projects[index].result.timeStamp}
            onChange={(e) => updateTimeStamp(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <InputControl
            label="Sample Size"
            type="number"
            placeholder="Enter sample size"
            value={projects[index].result.sampleSize}
            onChange={(e) => updateSampleSize(e.target.value)}
          />
          <InputControl
            label="Status"
            type="text"
            placeholder="Enter status"
            value={projects[index].result.status}
            onChange={(e) => updateStatus(e.target.value)}
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="p-value"
            type="number"
            placeholder="Enter the p-value"
            value={projects[index].result.pValue}
            onChange={(e) => updatepValue(e.target.value)}
          />
          <InputControl
            label="Alpha"
            type="number"
            placeholder="Enter the alpha value"
            value={projects[index].result.alpha}
            onChange={(e) => updatepAlpha(e.target.value)}
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Test Statistics"
            type="text"
            placeholder="Enter the Test Statistics"
            value={projects[index].result.testStatistics}
            onChange={(e) => updatetestStatistics(e.target.value)}
          />
          <InputControl
            label="Confidence Intervals"
            type="number"
            placeholder="Confidence intervals"
            value={projects[index].result.confidenceIntervals}
            onChange={(e) => updateconfidenceIntervals(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Result;
