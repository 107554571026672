import React, { useState } from "react";
import styles from "./Editor.module.css";
import Experiment from "../Experiment/Experiment";
import ProjectInfo from "../ProjectInfo/ProjectInfo";
import Result from "../Result/Result";
import Knobs from "../Knobs/Knobs.js";
import Data from "../Data/Data.js";
import Metrics from "../Metrics/Metrics.js";
import Conclusion from "../Conclusion/Conclusion.js";
import ImprovementAreas from "../Improvement Areas/ImprovementAreas";
import NotebookViewer from "../NotebookViewer/NotebookViewer";

const Editor = (props) => {
  const sections = props.sections;
  const [activeSectionKey, setActiveSectionKey] = useState(sections[0]);
  const handleNotebookViewerClick = () => {
    const externalLink = process.env.REACT_APP_NOTEBOOK_URL;
    window.open(externalLink, "_blank");
    setActiveSectionKey("Project Info");
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {sections?.map((key) => (
            <div
              className={`${styles.section} ${
                activeSectionKey === key ? styles.active : ""
              }`}
              key={key}
              onClick={() => setActiveSectionKey(key)}
            >
              {key}
            </div>
          ))}
        </div>
        <div className={styles.bodyContent}>
          {activeSectionKey === "Project Info" && (
            <ProjectInfo index={props.index} />
          )}
          {activeSectionKey === "Experiment" && (
            <Experiment index={props.index} />
          )}
          {activeSectionKey === "Data" && <Data index={props.index} />}
          {activeSectionKey === "Metrics" && <Metrics index={props.index} />}
          {activeSectionKey === "Notebook" && <NotebookViewer /> &&
            handleNotebookViewerClick()}
          {activeSectionKey === "Knobs" && <Knobs index={props.index} />}
          {activeSectionKey === "Result" && <Result index={props.index} />}
          {activeSectionKey === "Conclusion" && (
            <Conclusion index={props.index} />
          )}
          {activeSectionKey === "Improvement Areas" && (
            <ImprovementAreas index={props.index} />
          )}
        </div>
      </div>
    </>
  );
};

export default Editor;
