import React from "react";
import styles from "./ThemeItem.module.css"; // Import the CSS file for your ThemeItem component
import { NavLink } from "react-router-dom";

const ThemeItem = (props) => {
  return (
    <div className={`card ${styles.theme_card}`}>
      <div className="card-body text-center">
        <NavLink to={props.link} className={styles.card_title}>
          {props.heading}
        </NavLink>
      </div>
    </div>
  );
};

export default ThemeItem;
