import React, { useEffect, useState } from "react";
import { auth } from "../../firebase.js";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        navigate("/");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => unsubscribe();
  }, []);
  return (
    <>
      <div className="container-fluid bg-primary w-100">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark p-0">
            <a href="index.html" className="navbar-brand">
              <h1 className="text-white">
                AB<span className="text-dark">.</span>Experiment
              </h1>
            </a>
            <button
              type="button"
              className="navbar-toggler ms-auto me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse bg-primary"
              id="navbarCollapse"
            >
              <div className="navbar-nav ms-auto align-items-center">
                <NavLink to="/" className="nav-item nav-link">
                  Home
                </NavLink>
                <NavLink className="nav-item nav-link" to="/tools">
                  Tools
                </NavLink>
                <a
                  href="https://www.abexperiment.com/ab-testing/"
                  className="nav-item nav-link"
                >
                  A/B testing
                </a>
                <a
                  href="https://www.abexperiment.com/about-us/"
                  className="nav-item nav-link"
                >
                  About us
                </a>
                <div>
                  <div style={{ borderColor: "#1363C6" }}>
                    {user ? (
                      <div className="d-flex w-100 align-items-center justify-content-between ">
                        <NavLink className="nav-item nav-link" to="/my-account">
                          My Account
                        </NavLink>

                        <button
                          className="btn btn-primary btn-oval"
                          onClick={handleSignOut}
                        >
                          Log Out <i className="fa fa-sign-out-alt"></i>
                        </button>
                      </div>
                    ) : (
                      <Link className="btn btn-primary btn-oval" to="/login">
                        <i className="fa fa-sign-in-alt"></i> Sign In Now
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="btn text-white p-0 px-4 d-none d-lg-block"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
