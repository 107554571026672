import React from "react";
import styles from "./Sidebar.module.css";
import { useDataContext } from "../../DataContext";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const {
    setSelected,
    projectCnt,
    setProjectCnt,
    projects,
    setProjects,
    tempProject,
    selected,
  } = useDataContext();

  const addNewProject = () => {
    setProjects([...projects, tempProject]);
    setProjectCnt(projectCnt + 1);
    setSelected(projectCnt);
  };

  return (
    <div className={styles.sideBar}>
      <div className={styles.header}>Select Projects</div>
      <div className={styles.scrollContainer}>
        <ul className={styles.nav}>
          {Array.from({ length: projectCnt }, (_, index) => (
            <NavLink
              key={index} // Add a key to remove React warning
              onClick={() => {
                setSelected(index);
              }}
              className={
                selected === index ? styles.navsideactive : styles.navlink
              }
            >
              Project {index + 1}
            </NavLink>
          ))}
        </ul>
      </div>
      <NavLink className={styles.addProject} onClick={addNewProject}>
        Add new Project
      </NavLink>
    </div>
  );
};

export default Sidebar;
