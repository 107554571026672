// PDFGenerator.js
import { jsPDF } from "jspdf";

const generatePDF = (pdfData) => {
  const {
    projectInfo,
    experiment,
    data,
    metrics,
    knobs,
    result,
    conclusion,
    impareas,
  } = pdfData;

  const doc = new jsPDF();

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const lineHeight = 10;
  let yPos = 20;
  let currentPage = 1;

  const addText = (content) => {
    const textLines = doc.splitTextToSize(content, pageWidth - 40);
    if (yPos + textLines.length * lineHeight >= pageHeight - lineHeight) {
      doc.addPage();
      currentPage++;
      yPos = lineHeight;
    }
    doc.text(20, yPos, textLines);
    yPos += textLines.length * lineHeight;
  };

  const addHeading = (heading) => {
    const headingText = `--- ${heading} ---`;
    addText(headingText);
    yPos += lineHeight;
  };

  doc.setFontSize(16);
  addText("Project Report");

  // Project Info
  doc.setFontSize(12);
  addHeading("Project Info");
  addText(`Project Name: ${projectInfo.name}`);
  addText(`Description: ${projectInfo.description}`);
  addText(`Team: ${projectInfo.team}`);
  addText(`Primary Owner: ${projectInfo.primaryOwner}`);
  addText(`Secondary Owner: ${projectInfo.secondaryOwner}`);
  addText(`Experiment Set Planned: ${projectInfo.experimentSetPlanned}`);
  addText(`Feature Set Planned: ${projectInfo.featureSetPlanned}`);

  // Experiment
  addHeading("Experiment");
  experiment.forEach((exp, index) => {
    addText(`${index + 1}. Research Question: ${exp.researchQuestion}`);
    addText(`   Why Experiment: ${exp.whyExperiment}`);
    addText(`   Null Hypothesis: ${exp.nullHypothesis}`);
    addText(`   Field 1: ${exp.field1}`);
    addText(`   Field 2: ${exp.field2}`);
    addText(`   Field 3: ${exp.field3}`);
  });

  // Data
  addHeading("Data");
  addText(`Description: ${data.description}`);
  addText(`Source: ${data.source}`);
  addText(`Summary: ${data.summary}`);
  addText(`Train Set: ${data.trainSet}`);
  addText(`Validation Set: ${data.validationSet}`);
  addText(`Test Set: ${data.testSet}`);
  addText(`Randomization Method: ${data.randomizationMethod}`);

  // Metrics
  addHeading("Metrics");
  addText(`Primary Metric: ${metrics.primary}`);
  addText(`Secondary Metric: ${metrics.secondary}`);

  // Knobs
  addHeading("Knobs");
  addText(`Knob 1: ${knobs.knob1}`);
  addText(`Knob 2: ${knobs.knob2}`);
  addText(`Knob 3: ${knobs.knob3}`);
  addText(`Knob 4: ${knobs.knob4}`);

  // Result
  addHeading("Result");
  addText(`Test Name: ${result.testName}`);
  addText(`Time Stamp: ${result.timeStamp}`);
  addText(`Sample Size: ${result.sampleSize}`);
  addText(`Status: ${result.status}`);
  addText(`pValue: ${result.pValue}`);
  addText(`Alpha: ${result.alpha}`);
  addText(`Test Statistics: ${result.testStatistics}`);
  addText(`Confidence Intervals: ${result.confidenceIntervals}`);

  // Conclusion
  addHeading("Conclusion");
  addText(`Summary: ${conclusion.summary}`);
  addText(`Description: ${conclusion.description}`);

  // Improvement Areas
  addHeading("Improvement Areas");
  impareas.forEach((imp, index) => {
    addText(`${index + 1}. Item: ${imp.item}`);
    addText(`   Description: ${imp.desc}`);
  });

  // Save and download the PDF
  doc.save("project_report.pdf");
};

export default generatePDF;
