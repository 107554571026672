import React, { useState } from "react";
import { db } from "../../firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

const Subscribe = () => {
  const [subscriber, setSubscriber] = useState("");

  const subscriberCollectionRef = collection(db, "SubscriberList");

  const handleEmailChange = (e) => {
    setSubscriber(e.target.value);
  };

  const handleSubscribe = async () => {
    // Check if the email field is empty
    if (subscriber.trim() === "") {
      alert("Please enter your email");
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(subscriber)) {
      alert("Invalid email format");
      return;
    }

    const q = query(subscriberCollectionRef, where("userEmailId", "==", subscriber));
    const querySnapShot = await getDocs(q);

    if (!querySnapShot.empty) {
      console.log("Email already exists");
      setSubscriber("");
      return;
    }

    await addDoc(subscriberCollectionRef, { userEmailId: subscriber });
    setSubscriber("");
  };

  return (
    <>
      <div className="container-fluid bg-primary newsletter py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div
              className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn"
              data-wow-delay="0.3s"
            >
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/newsletter.png"
                alt=""
              />
            </div>
            <div
              className="col-md-7 py-5 newsletter-text wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                Newsletter
              </div>
              <h1 className="text-white mb-4">
                Let's subscribe to the newsletter
              </h1>
              <div className="position-relative w-100 mt-3 mb-2">
                <input
                  className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                  type="email"
                  placeholder="Enter Your Email"
                  value={subscriber}
                  onChange={handleEmailChange}
                  autoComplete="on"
                  required
                />
                <button
                  type="button"
                  className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                  onClick={handleSubscribe}
                >
                  <i className="fa fa-paper-plane text-primary fs-4"></i>
                </button>
              </div>
              <small className="text-white-50">
                Tips for websites, social media, online advertising, and AB
                testing websites
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
