import React from "react";
import styles from "./Conclusion.module.css";
import { useDataContext } from "../../DataContext";

const Conclusion = ({ index }) => {
  const { projects, setProjects } = useDataContext();
  const updateSummary = (summary) => {
    const updatedProjects = [...projects];
    updatedProjects[index].conclusion.summary = summary;
    setProjects(updatedProjects);
  };
  const updateDescription = (description) => {
    const updatedProjects = [...projects];
    updatedProjects[index].conclusion.description = description;
    setProjects(updatedProjects);
  };
  return (
    <div className={styles.detail}>
      <div className={styles.column}>
        Summary &nbsp;
        <textarea
          className={styles.textarea}
          placeholder="Summary"
          type="text"
          value={projects[index].conclusion.summary}
          onChange={(e) => updateSummary(e.target.value)}
        />
      </div>
      <div className={styles.column}>
        Description &nbsp;
        <textarea
          className={styles.textarea}
          placeholder="Description"
          type="text"
          value={projects[index].conclusion.description}
          onChange={(e) => updateDescription(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Conclusion;
