import React from "react";
import styles from "./Experiment.module.css";
import InputControl from "../InputControl/InputControl.js";
import { useDataContext } from "../../DataContext.js";
import Upload from "../Upload/Upload";

const Experiment = ({ index }) => {
  const { projects, setProjects } = useDataContext();

  const IncreaseWorkCount = () => {
    // Create a new experiment with default values and add it to the specified project
    setProjects((prevProjects) => {
      const updatedProjects = [...prevProjects];
      updatedProjects[index]?.experiments?.push({
        researchQuestion: "",
        whyExperiment: "",
        nullHypothesis: "",
        field1: "",
        field2: "",
        field3: "",
      });
      return updatedProjects;
    });
  };

  const handleDelete = (experimentIndex) => {
    // Create a copy of the projects array
    const updatedProjects = [...projects];

    // Remove the experiment from the specified project
    updatedProjects[index].experiments.splice(experimentIndex, 1);

    // Set the updated projects array
    setProjects(updatedProjects);
  };

  return (
    <div className={styles.detail}>
      <>
        <div className={styles.detail}>
          <div className={styles.workHeader}>
            <button
              className={styles.addHeader}
              onClick={() => IncreaseWorkCount()}
            >
              <span className={styles.addicon}>+</span>
              Add new Experiment
            </button>
          </div>
          {projects[index]?.experiments?.map((exp, experimentIndex) => (
            <div className={styles.workExpBody} key={experimentIndex}>
              <div className={styles.header}>
                Experiment {experimentIndex + 1}
                &nbsp;&nbsp;&nbsp;
                {projects[index]?.experiments?.length > 1 && (
                  <span
                    className={styles.deleteIcon}
                    onClick={() => handleDelete(experimentIndex)}
                  >
                    ❌
                  </span>
                )}
              </div>
              <div className={styles.row}>
                <InputControl
                  label="Research Question"
                  placeholder="Enter Research Question"
                  value={exp.researchQuestion}
                  onChange={(e) => {
                    let newProjects = [...projects];
                    newProjects[index].experiments[
                      experimentIndex
                    ].researchQuestion = e.target.value;
                    setProjects(newProjects);
                  }}
                />
                <InputControl
                  label="Null Hypothesis"
                  placeholder="Enter Null Hypothesis"
                  value={exp.nullHypothesis}
                  onChange={(e) => {
                    let newProjects = [...projects];
                    newProjects[index].experiments[
                      experimentIndex
                    ].nullHypothesis = e.target.value;
                    setProjects(newProjects);
                  }}
                  type="text"
                />
              </div>
              <div className={styles.column}>
                Why Experiment? &nbsp;
                <textarea
                  className={styles.textarea}
                  placeholder="Why Experiment?"
                  value={exp.whyExperiment}
                  type="text"
                  onChange={(e) => {
                    let newProjects = [...projects];
                    newProjects[index].experiments[
                      experimentIndex
                    ].whyExperiment = e.target.value;
                    setProjects(newProjects);
                  }}
                />
              </div>
              <div className={styles.column}>
                Add Experiment Designs &nbsp;
                <div className={styles.row}>
                  <InputControl
                    label="Field 1"
                    type="text"
                    placeholder="Enter Field 1"
                    value={exp.field1}
                    onChange={(e) => {
                      let newProjects = [...projects];
                      newProjects[index].experiments[experimentIndex].field1 =
                        e.target.value;
                      setProjects(newProjects);
                    }}
                  />
                  <InputControl
                    label="Field 2"
                    type="text"
                    placeholder="Enter Field 2"
                    value={exp.field2}
                    onChange={(e) => {
                      let newProjects = [...projects];
                      newProjects[index].experiments[experimentIndex].field2 =
                        e.target.value;
                      setProjects(newProjects);
                    }}
                  />
                  <InputControl
                    label="Field 3"
                    type="text"
                    placeholder="Enter Field 3"
                    value={exp.field3}
                    onChange={(e) => {
                      let newProjects = [...projects];
                      newProjects[index].experiments[experimentIndex].field3 =
                        e.target.value;
                      setProjects(newProjects);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
      <Upload tab="experiment" index={index} />
      <a href={projects[index].links.experiment}>
        {projects[index].links.experiment}
      </a>
    </div>
  );
};

export default Experiment;
