import React from "react";
import styles from "./Metrics.module.css";
import InputControl from "../InputControl/InputControl.js";
import { useDataContext } from "../../DataContext.js";
import Upload from "../Upload/Upload.js";

const Metrics = ({ index }) => {
  const { projects, setProjects } = useDataContext();

  const updatePrimary = (primary) => {
    const updatedProjects = [...projects];
    updatedProjects[index].metrics.primary = primary;
    setProjects(updatedProjects);
  };
  const updateSecondary = (secondary) => {
    const updatedProjects = [...projects];
    updatedProjects[index].metrics.secondary = secondary;
    setProjects(updatedProjects);
  };

  return (
    <div className={styles.detail}>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Primary Metrics"
            type="text"
            placeholder="Enter the Primary Metrics"
            value={projects[index].metrics.primary}
            onChange={(e) => updatePrimary(e.target.value)}
          />
          <InputControl
            label="Secondary Metrics"
            type="text"
            placeholder="Enter the Primary Metrics"
            value={projects[index].metrics.secondary}
            onChange={(e) => updateSecondary(e.target.value)}
          />
        </div>
      </div>
      <Upload tab="metrics" index={index} />
      <a href={projects[index].links.metrics}>
        {projects[index].links.metrics}
      </a>
    </div>
  );
};

export default Metrics;
