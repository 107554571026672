import React from "react";
import styles from "./Data.module.css";
import InputControl from "../InputControl/InputControl.js";
import { useDataContext } from "../../DataContext.js";
import Upload from "../Upload/Upload.js";

const Data = ({ index }) => {
  const { projects, setProjects } = useDataContext();
  const updateDescription = (description) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.description = description;
    setProjects(updatedProjects);
  };
  const updateSource = (source) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.source = source;
    setProjects(updatedProjects);
  };
  const updateSummary = (summary) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.summary = summary;
    setProjects(updatedProjects);
  };
  const updatetrainSet = (trainSet) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.trainSet = trainSet;
    setProjects(updatedProjects);
  };
  const updatevalidationSet = (validationSet) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.validationSet = validationSet;
    setProjects(updatedProjects);
  };
  const updatetestSet = (testSet) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.testSet = testSet;
    setProjects(updatedProjects);
  };
  const updaterandomizationMethod = (randomizationMethod) => {
    const updatedProjects = [...projects];
    updatedProjects[index].data.randomizationMethod = randomizationMethod;
    setProjects(updatedProjects);
  };
  return (
    <div className={styles.detail}>
      <div>
        <div className={styles.detail}>
          <div className={styles.row}>
            <InputControl
              label="Data Description"
              type="text"
              placeholder="Enter the data description"
              value={projects[index].data.description}
              onChange={(e) => updateDescription(e.target.value)}
            />
            <InputControl
              label="Data Source"
              type="text"
              placeholder="Enter the Data Source"
              value={projects[index].data.source}
              onChange={(e) => updateSource(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <InputControl
              label="Data Summary"
              type="text"
              placeholder="Enter Data Summary"
              value={projects[index].data.summary}
              onChange={(e) => updateSummary(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <InputControl
              label="Train Set"
              type="text"
              placeholder="Enter the Train Set"
              value={projects[index].data.trainSet}
              onChange={(e) => updatetrainSet(e.target.value)}
            />
            <InputControl
              label="Validation Set"
              type="text"
              placeholder="Enter the Validation Set"
              value={projects[index].data.validationSet}
              onChange={(e) => updatevalidationSet(e.target.value)}
            />
            <InputControl
              label="Test Set"
              type="text"
              placeholder="Enter the Test Set"
              value={projects[index].data.testSet}
              onChange={(e) => updatetestSet(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <InputControl
              label="Randomization Method Used"
              type="text"
              placeholder="Randomization Method Used"
              value={projects[index].data.randomizationMethod}
              onChange={(e) => updaterandomizationMethod(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Upload tab="data" index={index}/>
      <a href={projects[index].links.data}>{projects[index].links.data}</a>
    </div>
  );
};

export default Data;
