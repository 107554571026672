import React, { useState } from "react";
import InputControl from "../InputControl/InputControl";
import styles from "./ProjectInfo.module.css";
import { useDataContext } from "../../DataContext.js";
import Upload from "../Upload/Upload.js";

const ProjectInfo = ({ index }) => {
  const { projects, setProjects } = useDataContext();
  const [current, setCurrent] = useState("");
  const [team, setTeam] = useState([]);

  const handleAdd = () => {
    if (current.trim() !== "") {
      const updatedTeam = [...team, current];
      setTeam(updatedTeam);
      const updatedProjects = [...projects];
      updatedProjects[index].projectInfo.team = updatedTeam;
      setProjects(updatedProjects);
      setCurrent("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAdd();
    }
  };

  const updateName = (name) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.name = name;
    setProjects(updatedProjects);
  };

  const updateDescription = (description) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.description = description;
    setProjects(updatedProjects);
  };

  const updatePrimaryOwner = (primaryOwner) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.primaryOwner = primaryOwner;
    setProjects(updatedProjects);
  };
  const updateSecondaryOwner = (secondaryOwner) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.secondaryOwner = secondaryOwner;
    setProjects(updatedProjects);
  };
  const updateExperimentSetPlanned = (experimentSetPlanned) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.experimentSetPlanned =
      experimentSetPlanned;
    setProjects(updatedProjects);
  };
  const updateFeatureSetPlanned = (featureSetPlanned) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectInfo.featureSetPlanned = featureSetPlanned;
    setProjects(updatedProjects);
  };

  return (
    <div className={styles.detail}>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Name"
            type="text"
            placeholder="Enter the name"
            value={projects[index].projectInfo.name}
            onChange={(e) => updateName(e.target.value)}
          />
          <InputControl
            label="Description"
            value={projects[index].projectInfo.description}
            type="text"
            placeholder="Enter the description"
            onChange={(e) => updateDescription(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <InputControl
            label="Primary Owner"
            type="text"
            placeholder="Enter the name of the primary owner"
            value={projects[index].projectInfo.primaryOwner}
            onChange={(e) => updatePrimaryOwner(e.target.value)}
          />
          <InputControl
            label="Secondary Owner"
            type="text"
            placeholder="Enter the name of the secondary owner"
            value={projects[index].projectInfo.secondaryOwner}
            onChange={(e) => updateSecondaryOwner(e.target.value)}
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Experiment Set Planned"
            type="text"
            placeholder="Experiment Set Planned"
            value={projects[index].projectInfo.experimentSetPlanned}
            onChange={(e) => updateExperimentSetPlanned(e.target.value)}
          />
          <InputControl
            label="Feature Set Planned"
            type="text"
            placeholder="Feature Set Planned"
            value={projects[index].projectInfo.featureSetPlanned}
            onChange={(e) => updateFeatureSetPlanned(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.detail}>
        <InputControl
          label="Add Team Members"
          placeholder="Add team member email ID"
          value={current}
          type="email"
          onChange={(e) => {
            setCurrent(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <button className={styles.achButton} onClick={handleAdd}>
          Add
        </button>
        {projects[index].projectInfo.team.length > 0 ? (
          projects[index].projectInfo.team.map((member, id) => {
            return <li key={id}>{member}</li>;
          })
        ) : (
          <li>No team members yet.</li>
        )}
      </div>

      <Upload tab="projectInfo" index={index} />
      <a href={projects[index].links.projectInfo}>
        {projects[index].links.projectInfo}
      </a>
    </div>
  );
};

export default ProjectInfo;
