import React from "react";
import Navbar from "../../components/Navbar/Navbar.js";
import ThemeItem from "../../components/ThemeItem/ThemeItem.js";
import styles from "./Tools.module.css"; // Import the CSS file for your component

const Tools = () => {
  const themes = [
    "Baseline Conversion Rate",
    "Sample Size",
    "AB Test Assumption",
    "Chi Squared Test",
    "Two Sample T Test",
    "Paired T Test",
    "Mann Whitney U Test",
    "Wilcoxon Signed Rank Test",
  ];
  const links = [
    "/tools/baseline-conversion-rate",
    "/tools/sample-size-calculator",
    "/tools/ab-test-assumption",
    "/tools/chisquared-test",
    "/tools/two-sample-t-test",
    "/tools/paired-t-test",
    "/tools/mann-whitney-U-test",
    "/tools/wilcoxon-signed-rank-test",
  ];

  return (
    <>
      <Navbar />
      <div className={styles.app_container}>
        <div className={styles.themes_grid}>
          {themes.map((theme, index) => (
            <ThemeItem key={index} heading={theme} link={links[index]} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Tools;
