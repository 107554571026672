import { useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar.js";
import styles from "./ABTestAssumption.module.css";
import { Helmet } from "react-helmet";
import Plot from "react-plotly.js";

function PairedTTest() {
  const apiKey = process.env.REACT_APP_VITE_API_KEY;
  const [formData, setFormData] = useState({
    file: null,
    sample_size: 0,
    column1: "",
    column2: "",
    condition: "",
    alpha: 0.05,
    reverse_experiment: "False",
    alternative: "two-sided",
    file_name: "data.json",
  });
  const [outputData, setOutputData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [col_values, setColumnValues] = useState([]);
  const [json_fig_object, setJson] = useState([]);
  const [box_plot, setBoxPlot] = useState([]);
  const config = {
    displaylogo: false,
    modeBarButtonsToRemove: ["pan2d", "zoom2d", "zoom3d", "autoScale2d"],
  };
  const handleChange = async (evt) => {
    const changedField = evt.target.name;
    let newValue;
    if (changedField === "file") {
      newValue = evt.target.files[0];
      try {
        const formData2 = new FormData();
        formData2.append("file", newValue);
        await axios({
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            "x-functions-key": `${apiKey}`,
          },
          url: `https://functions-abexp.azurewebsites.net/get_columns`,
          data: formData2,
        }).then((res) => {
          setColumns(res.data.columns);
        });
      } catch (e) {
        console.log(e.message);
      }
    } else {
      newValue = evt.target.value;
      if (changedField === "column1") {
        try {
          const formData3 = new FormData();
          formData3.append("file", formData.file);
          formData3.append("column1", newValue);
          await axios({
            method: "post",
            headers: {
              "Content-Type": "multipart/form-data",
              "x-functions-key": `${apiKey}`,
            },
            url: `https://functions-abexp.azurewebsites.net/get_column_values`,
            data: formData3,
          }).then((res) => {
            setColumnValues(res.data.column_val);
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    }
    setFormData((currData) => {
      currData[changedField] = newValue;
      return { ...currData };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-functions-key": `${apiKey}`,
        },
        url: `https://functions-abexp.azurewebsites.net/paired_ttest`,
        data: formData,
      }).then((res) => {
        const json_fig = JSON.parse(res.data.fig);
        const json_box_plot = JSON.parse(res.data.box_plot);
        setJson(json_fig);
        setBoxPlot(json_box_plot);
        setOutputData(res.data);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const pageDescription =
    "Paired_ttest determines whether there is a statistically significant  difference between the means of the two groups based on the paired observations.";
  const pageKeywords =
    "sample size, alpha, statistic, p-value, Confidence interval";

  return (
    <>
      <Helmet>
        <title>Paired T test</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
      <Navbar />
      <div className={styles.app_container}>
        <div className={styles.head}>
          <h2 className={styles.midheading}>Paired T test</h2>
          <p className={styles.pageDescription}>{pageDescription}</p>
        </div>

        <div className={styles.input_container}>
          <h3 className={styles.subHeading}>Input</h3>
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Data</label>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Sample Size</label>
                <input
                  type="number"
                  name="sample_size"
                  value={formData.sample_size}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Column-1</label>
                <select
                  name="column1"
                  value={formData.column1}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Condition</label>
                <select
                  name="condition"
                  value={formData.condition}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Value--</option>
                  {col_values.map((col_value, index) => (
                    <option key={index} value={col_value}>
                      {col_value}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Column-2</label>
                <select
                  name="column2"
                  value={formData.column2}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="">--Select a Column</option>
                  {columns.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Alpha</label>
                <input
                  type="number"
                  name="alpha"
                  value={formData.alpha}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>Reverse Experiment</label>
                <select
                  name="reverse_experiment"
                  value={formData.reverse_experiment}
                  onChange={handleChange}
                  className={styles.design}
                >
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
              </div>
              <div className={styles.formElement}>
                <label className={styles.label}>Alternative</label>
                <input
                  type="text"
                  name="alternative"
                  value={formData.alternative}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.formElement}>
                <label className={styles.label}>File Name</label>
                <input
                  type="text"
                  name="file_name"
                  value={formData.file_name}
                  onChange={handleChange}
                  className={styles.design}
                />
              </div>
            </div>

            <div className={styles.formElement}>
              <button className={styles.button}>Submit</button>
            </div>
          </form>
        </div>

        <div className={styles.input_container}>
          <h3 className={styles.subHeading}>Result</h3>
          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>Timestamp</label>
              <input
                className={styles.design}
                type="text"
                value={outputData.Timestamp}
              />
            </div>
            <div className={styles.formElement}>
              <label className={styles.label}>Control Group</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["Control group"]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>Treatment group</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["Treatment group"]}
              />
            </div>
            <div className={styles.formElement}>
              <label className={styles.label}>Sample Size</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["Sample size"]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>Status</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["Status"]}
              />
            </div>
            <div className={styles.formElement}>
              <label className={styles.label}>P-value</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["P-value"]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>alpha</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["alpha"]}
              />
            </div>
            <div className={styles.formElement}>
              <label className={styles.label}>Test Statistics</label>
              <input
                className={styles.design}
                type="text"
                value={outputData["Test Statistic"]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>
                Confidence interval of group {outputData["Control group"]}
              </label>
              <input
                type="text"
                value={
                  outputData[
                    `Confidence interval of group ${outputData["Control group"]}`
                  ]
                }
                className={styles.design}
              />
            </div>
            <div className={styles.formElement}>
              <label className={styles.label}>
                Confidence interval of group {outputData["Treatment group"]}
              </label>
              <input
                type="text"
                value={
                  outputData[
                    `Confidence interval of group ${outputData["Treatment group"]}`
                  ]
                }
                className={styles.design}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.formElement}>
              <label className={styles.label}>
                Confidence interval of difference in groups
              </label>
              <input
                type="text"
                value={
                  outputData["Confidence interval of difference in groups"]
                }
                className={styles.design}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.plot}>
            <Plot
              data={json_fig_object.data}
              layout={json_fig_object.layout}
              config={config}
            ></Plot>
          </div>
          <div className={styles.plot}>
            <Plot
              data={box_plot.data}
              layout={box_plot.layout}
              config={config}
            ></Plot>
          </div>
        </div>
      </div>
    </>
  );
}

export default PairedTTest;
